import React, { useRef, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import Intro from '@/components/Intro';
import Content from '@/components/Content';

type PageContextProps = {
  id: string;
};

type DataProps = {
  datoCmsTempHomepage: any;
};

const Homepage = ({ data }: PageProps<DataProps, PageContextProps>) => {
  const page = data.datoCmsTempHomepage;
  const scrollAnimRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [activeTab, setActiveTab] = useState<string>('business');
  const videoId = page?.video?.providerUid;

  return (
    <Layout navChangeColor={true} variant="light">
      <Seo title="Home" />
      <div className="bg-gray-200">
        <Intro
          previewVideoId={page.heroVideo.providerUid}
          videoThumbnail={
            <GatsbyImage
              className="h-full w-full"
              image={page.videoThumbnail?.gatsbyImageData}
              alt={page.videoTitle}
            />
          }
        />
      </div>
      <div className="bg-gray-200 pb-8 md:pb-0 pt-24" ref={scrollAnimRef}>
        <div className="mx-6 md:mx-8 lg:mx-16 max-w-screen-xl xl:mx-auto 2xl:px-16">
          <div>
            <div
              className={classNames('text-left max-w-screen-lg', {
                'mt-16': !videoId,
              })}
            >
              <h1 className="text-2xl md:text-4xl font-medium">
                {page.subtitle}
              </h1>
            </div>

            <div className="md:flex mt-8 items-center text-center pt-40">
              <button
                onClick={() => setActiveTab('business')}
                className={classNames(
                  'md:first:mr-4 mb-4 block py-7 px-10 rounded-full uppercase font-medium no-underline tracking-widest text-xs sm:text-sm border-black hover:opacity-90 ',
                  {
                    'text-white bg-black': activeTab === 'business',
                    'text-gray-600 bg-gray-300/70': activeTab !== 'business',
                  },
                )}
              >
                {page.businessText}
              </button>
              <button
                onClick={() => setActiveTab('coffeelover')}
                className={classNames(
                  'md:first:mr-4 mb-4 block py-7 px-10 rounded-full uppercase font-medium no-underline tracking-widest text-xs sm:text-sm border-black hover:opacity-90 ',
                  {
                    'text-white bg-black': activeTab === 'coffeelover',
                    'text-gray-600 bg-gray-300/70': activeTab !== 'coffeelover',
                  },
                )}
              >
                {page.coffeeLoverText}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-gray-200 py-28 min-h-screen">
            <div
              className={classNames(
                'mx-6 md:mx-8 lg:mx-16 max-w-screen-xl xl:mx-auto 2xl:px-16',
              )}
            >
              {activeTab === 'business' ? (
                <>
                  {page.business?.map((record: any) => (
                    <>
                      <Content record={record}></Content>
                    </>
                  ))}
                </>
              ) : activeTab === 'coffeelover' ? (
                <>
                  {page.coffeeLover?.map((record: any) => (
                    <>
                      <Content record={record}></Content>
                    </>
                  ))}
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String) {
    datoCmsTempHomepage(locale: { eq: $locale }) {
      subtitle
      videoTitle
      heroVideo {
        providerUid
      }
      videoThumbnail {
        gatsbyImageData
      }
      coffeeLoverText
      coffeeLover {
        ...Blocks
      }
      businessText
      business {
        ...Blocks
      }
    }
  }
`;

export default Homepage;
